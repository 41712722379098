@import '../../common';

.link {
  margin-right: $header-link-margin;
}

.iconWrapper {
  position: relative;

  .signInIcon {
    margin-top: -.25em;
    vertical-align: middle;
    margin-right: 12px;
  }
}



